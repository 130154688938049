<script>
import {UBtn, UField, UInput, UAjaxBar, UCheckbox} from 'uloc-vue'
import LoginMixin from '../login.mixin.js'

export default {
  name: 'Auth',
  inject: ['appRoot'],
  mixins: [LoginMixin],
  components: {UAjaxBar, UBtn, UCheckbox, UField, UInput},
  props: {},
  data() {
    return {
      loginErrorData: null,
      isSubmiting: false
    }
  },
  beforeCreate() {
    this.lang = this.$uloc ? this.$uloc.i18n.lang : 'pt-BR' // TODO: Desenvolver próprio sistema de línguas do Auth
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    __doLogin() {
      this.$refs.bar.start()
      this.isSubmiting = true
      this.doLogin()
          .then((r) => {
            if (this.$route.query.redirect && this.$route.query.redirect !== '/logout') {
              this.$router.push({path: this.$route.query.redirect})
            } else {
              this.$router.push('/')
            }
            this.isSubmiting = false
          })
          .catch((err) => {
            this.isSubmiting = false
            console.log('ERRO LOGIN')
            console.log('Login error', err)
            this.loginErrorData = err.message || 'Ocorreu algum erro no login'
            this.$refs.bar.stop()
            this.$uloc.notify({
              type: 'negative',
              message: this.loginErrorData
            })
          })
    }
  },
  computed: {
    isLogged: function () {
      return this.$uloc ? this.$uloc.auth.logged : this.$auth.logged
    }
  }
}
</script>

<template>
  <div class="sl-auth" v-if="!isLogged">
    <div class="sl-login animated">
      <div class="login-form">
        <div class="login-header">
            <div class="lh-logo">
              <a href="https://www.suporteleiloes.com.br" target="_blank"><img src="@/assets/images/sl-logo-saas.png" /></a>
            </div>
            <div class="lh-poslogo" v-if="appRoot.installed && $appConfig && $appConfig.isConfigNotEmpty('empresa.license') && $appConfig.getConfig('empresa.license') !== 'enterprise'">Não tem acesso? <a target="_blank" href="https://www.suporteleiloes.com.br/atendimento">Clique aqui</a></div>
        </div>
        <form class="auth-form" method="post" @submit.prevent="__doLogin">
<!--          <div class="m-t sl-alert sl-alert-warning bg-yellow-2">Informamos que estamos concluindo a migração do software para a versão 3.0.0, dentro de 24h terminaremos nossos trabalhos e esta mensagem desaparecerá.</div>-->
          <div class="block m-t-lg"></div>
          <div class="login-form-pre-header">
            <div class="login-lbl">Conectar ao Console</div>
          </div>
          <u-field
              :label-width="12"
              label="Login"
          >
            <u-input required type="text" v-model="user.email" autofocus class="login-input" hide-underline/>
          </u-field>
          <u-field
              :label-width="12"
              label="Senha de acesso"
              class="m-t-lg"
          >
            <u-input required type="password" v-model="user.password" class="login-input" hide-underline/>
          </u-field>

          <div class="text-left m-t-lg text-grey-7">
            <u-checkbox label="Lembrar-me deste computador" v-model="rememberMe"></u-checkbox>
          </div>
          <u-btn :disabled="isSubmiting" type="submit" color="black" size="md"
                 label="Entrar"
                 class="w-full m-t-md sl-btn bg-black" no-caps />
          <div class="m-t-md text-center">
            <a class="cursor-pointer login-recuperar-senha">Esqueci minha senha</a>
          </div>
        </form>
      </div>
      <div class="login-footer">
      </div>
    </div>
    <div class="sl-login-banner">
    </div>
    <u-ajax-bar ref="bar" color="primary"/>
  </div>
</template>
