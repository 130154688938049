import http from '../../utils/services/http'

export const STATS_ENDPOINT = process.env.VUE_APP_STATS
const transformRequest = (data, headers) => {
    delete headers.common['Authorization']
    return data
}

export const work = (userId, date, secondsWorked) => {
    let url = `${STATS_ENDPOINT}/workedHours`
    return http.post(url, {
        userId: userId,
        date: date,
        seconds: secondsWorked
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
