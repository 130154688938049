<script>
import './fonts.js'
import 'uloc-vue/dist/uloc.styl'
import 'uloc-vue-auth/assets/styl/app.styl'
import 'uloc-vue-plugin-erp/assets/styl/app.styl'
import 'uloc-vue-plugin-erp/assets/styl/icons.styl'
import menu from './menu'
import {createComunicator} from './plugins/comunicator'
import http from '@/utils/services/http'
import {testRoles} from '@/utils/security'
import {getPublicConfig} from "@/domain/globalconfig/services"
import Vue from "vue"
import WorkMixin from "@/mixins/WorkMixin"

Vue.prototype.testRoles = testRoles

Vue.prototype.$appConfig = {}
Vue.prototype.$globalConfig = {}
Vue.prototype.$appConfig.testConfig = function (c) {
  return Vue.prototype.$globalConfig && Vue.prototype.$globalConfig[c] && Vue.prototype.$globalConfig[c].value !== null && Vue.prototype.$globalConfig[c].value !== 'null'
}
Vue.prototype.$appConfig.getConfig = function (c) {
  return Vue.prototype.$globalConfig[c] ? Vue.prototype.$globalConfig[c].value : null
}
Vue.prototype.$appConfig.isConfigTrue = function (c) {
  if (typeof Vue.prototype.$globalConfig[c] !== 'undefined' && typeof Vue.prototype.$globalConfig[c].value !== 'undefined') {
    const value = Vue.prototype.$globalConfig[c].value
    return value === '1' || value === 1 || value === true || value === 'true' || value === 's' || value === 'y'
  }
  return false
}
Vue.prototype.$appConfig.isConfigNotEmpty = function (c) {
  if (typeof Vue.prototype.$globalConfig[c] !== 'undefined' && typeof Vue.prototype.$globalConfig[c].value !== 'undefined') {
    const value = Vue.prototype.$globalConfig[c].value
    return value !== '0' && value !== 'null' && value !== 'NULL' && value !== null && value !== '' && value !== ' '
  }
  return false
}

export default {
  name: 'AppRoot',
  provide: function () {
    return {
      appRoot: this
    }
  },
  mixins: [WorkMixin],
  components: {},
  data() {
    return {
      installed: false,
      setupProgress: 0
    }
  },
  computed: {
    session() {
      console.log('Session', this.$uloc.auth.session.user)
      return {
        ...this.$uloc.auth.session.user
      }
    }
  },
  methods: {
    fetchPublicConfig () {
      getPublicConfig()
          .then(response => {
            if (!response || !response.data) {
              this.installed = true
              return
            }
            if (response.data['sistema.version'] && this.$uloc.auth.logged && this.$uloc.auth.session && this.$uloc.auth.session.user && this.$uloc.auth.session.user.config) {
              this.$uloc.auth.session.user.config.business['appVersion'] = response.data['appVersion']
            }
            if (typeof response.data['erp.login.css'] !== 'undefined' && response.data['erp.login.css'].value) {
              const sheet = document.createElement('style')
              console.log('Aplicando folha de estilo no ERP')
              sheet.innerHTML = response.data['erp.login.css'].value
              document.body.appendChild(sheet)
              delete response.data['erp.login.css']
            }
            if (typeof response.data['empresa.favicon'] !== 'undefined' && response.data['empresa.favicon'].value && response.data['empresa.favicon'].value !== 'null' && response.data['empresa.favicon'].value !== '0') {
              const favicon = document.getElementById('app-favicon')
              if (favicon) {
                favicon.href = response.data['empresa.favicon'].value
              }
            }
            setTimeout(() => {
              if (this.$uloc.auth.logged) {
                if (response.data['appVersion']) {
                  console.log('Versão cloud', response.data['appVersion'])
                }
              }
            }, 1500)
            console.log(response)
            this.config = response.data
            Vue.set(Vue.prototype, '$globalConfig', this.config)
            // Vue.prototype.$globalConfig = this.config
            this.$nextTick(() => {
              setTimeout(() => {
                this.loader = true
                this.installed = true
              }, 100)
            })
          })
          .catch(error => {
            this.loader = true
            this.installed = true
            setTimeout(() => {
              this.fetchPublicConfig()
            }, 5000)
            console.log(error)
          })
    }
  },
  created() {
    // Add a response interceptor
    http.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response && error.response.data && error.response.data.error && error.response.data.error === 'authorization') {
        console.log(error)
        this.$uloc.dialog({
          title: 'Você não tem permissão de acesso',
          message: error.response.config.url
        })
      }
      return Promise.reject(error);
    });

    this.$checkPublicConfig = setInterval(() => {
      if (this.$globalConfig && this.$globalConfig.clientId) {
        clearInterval(this.$checkPublicConfig)
      }
      this.fetchPublicConfig()
    }, 5000)
    this.fetchPublicConfig()
  },
  mounted() {
  },
  watch: {
    'session'(v) {
      console.log(v)
    },
    '$uloc.auth.logged'(v) {
      if (v) {
        if (!this.$uloc.auth.session.user.roles.includes('ROLE_ERP') && !this.$uloc.auth.session.user.roles.includes('ROLE_SUPORTE')) {
          this.$uloc.notify('Você não tem permissão para acessar esta área.')
          this.$router.push('/logout')
        }
      } else {
      }
    }
  }
}
</script>

<template>
  <div id="uloc-app" class="app">
    <router-view />
  </div>
</template>

<style src="./assets/style.styl" lang="stylus"></style>
